/**
 * @author Gowtham Prasath
 * @description Promo Code Apply and Viewing Dialog Box
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, CardContent, Typography } from "@mui/material";
import "../../../Pages/Common/drawer.css";
import "./successBanner.css";
import { dialogStyles } from "./newPromoDialogCss";
import makeStyles from "@mui/material";
import { ApplyPromocode } from "../../../Redux/actions/promocodesAction";
import appColors from "../../../Assets/AppTheme/appColors";
import PromoDisclaimer from "./promoDisclimer";
import { ResetPromoComponentState } from "../../../Redux/actions/ordersAction";

export default function PromocodesNewCard({
  openPromoDialog,
  setOpenPromoDialog,
  twtyLtrWaterPrice,
  bookingFormObj,
  setPromoApplied,
  setPromoId,
  searchPromoCodes,
  setPromoFlag,
  setHandePromoFlag,
  setpromoCode,
  promoId,
  setpreviousPromoCode
}) {
  const [promoSearch, setPromoSearch] = React.useState("");
  const [promoSearchFlag, setPromoSearchFlag] = React.useState("");
  const dispatch = useDispatch();
  const promocodeData = useSelector((state) => state.promocodeDetails);
  const [promoDisclimerDialog, setPromoDisclimerDialog] = React.useState(false);
  const [promoDisclimeMsg, setPromoDisclimerMsg] = React.useState([]);
  // const referAndEarnDetails = useSelector((state) => state.referAndEarnDetails);
  //Function for closing Dialog Box
  const handleClose = () => {
    setOpenPromoDialog(false);
  };

  const getPromoId = (data) => {
    if (bookingFormObj?.orderQty !== undefined) {
      const reqObj = {
        final_water_price: bookingFormObj.orderQty * twtyLtrWaterPrice,
        can_count: bookingFormObj.orderQty ? bookingFormObj.orderQty : 0,
        is_wallet: false,
        // isRemovePromo: true,
      };

      dispatch(ApplyPromocode(reqObj, data.promo_master_id));
      setPromoApplied(true);
      setPromoFlag(true);
      setPromoId(data.promo_master_id);
      setHandePromoFlag(false);
      setpreviousPromoCode(data.promo_label)
    } else {
      setpromoCode(data.promo_label);
      setPromoApplied(true);
      setPromoFlag(true);
      setPromoId(data.promo_master_id);
      setHandePromoFlag(false);
      setpreviousPromoCode(data.promo_label)
    }
  };

  const handlePromoChange = (e) => {
    setPromoSearch(e.target.value);
  };

  const promoDisclimer = (data) => {
    setPromoDisclimerDialog(true);
    setPromoDisclimerMsg(data);
  };

  React.useEffect(() => {
    if (promoSearchFlag) {
      searchPromoCodes(promoSearch);
      setPromoSearchFlag(false);
      RemovePromo();
    } else if (promoSearch === "") {
      searchPromoCodes(promoSearch);
      setPromoSearchFlag(false);
    }
  }, [promoSearchFlag, twtyLtrWaterPrice]);

  // React.useEffect(() => {
  //   RemovePromo();
  // }, [twtyLtrWaterPrice]);

  const RemovePromo = () => {
    setpromoCode("");
    setPromoId("");
    setpreviousPromoCode("")
  };

  return (
    <>
      <Card sx={dialogStyles.dialogContent1}>
        <Box sx={dialogStyles.textFieldBox}>
          <Typography sx={dialogStyles.typograpy1}>
            Coupon Code & Offers
          </Typography>
          <Box sx={[dialogStyles.scrollPromoCard, { marginTop: ".5rem" }]}>
            {promocodeData.error === false &&
            promocodeData.Promocodedata.length > 0 ? (
              <Box>
                {promocodeData.Promocodedata.map((data, index) => {
                  return (
                    <Box sx={[dialogStyles.mainCard]} key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          // boxShadow: 4,
                          borderRadius: "10px",
                        }}
                      >
                        <Box
                          sx={[
                            dialogStyles.cardContent,
                            {
                              padding: ".5rem 1rem",
                              margin:"0 .5rem",
                              backgroundColor:
                                data.promoDisabled === true ||
                                twtyLtrWaterPrice <
                                  parseInt(data?.min_charges_to_use)
                                  ? "#58595b"
                                  : appColors.secondaryCard,
                              borderRadius: ".5rem",
                              boxShadow: 5,
                            },
                          ]}
                        >
                          <Box
                            onClick={() => promoDisclimer(data)}
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              sx={{
                                color: appColors.headerTextColor,
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              {data?.promo_label}
                            </Typography>
                            <Typography
                              sx={{
                                color:
                                  data.promoDisabled === true ||
                                  twtyLtrWaterPrice <
                                    parseInt(data?.min_charges_to_use)
                                    ? appColors.white
                                    : appColors.darkGray,
                                fontSize: ".9rem",
                                fontWeight: "bold",
                              }}
                            >
                              Tap for details
                            </Typography>
                          </Box>
                          {data.promoDisabled === true ? (
                            ""
                          ) : (
                            <Button
                              sx={{
                                color:
                                  promoId !== data.promo_master_id
                                    ? appColors.secondaryText
                                    : "red",
                                margin: -1,
                              }}
                              disabled={
                                twtyLtrWaterPrice <
                                parseInt(data?.min_charges_to_use)
                              }
                              size="small"
                              onClick={
                                promoId !== data.promo_master_id
                                  ? () => getPromoId(data)
                                  : RemovePromo
                              }
                            >
                              {promoId !== data.promo_master_id
                                ? "APPLY"
                                : "Remove"}
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  padding: "10px",
                  color: appColors.textColorNew,
                }}
              >
                No promo codes Available
              </Typography>
            )}
          </Box>
        </Box>
        {promoDisclimerDialog && (
          <PromoDisclaimer
            open={promoDisclimerDialog}
            setOpen={setPromoDisclimerDialog}
            promoDisclimeMsg={promoDisclimeMsg}
          
          />
        )}
      </Card>
    </>
  );
}
